/** @jsx jsx */
import { jsx, Box, Flex, Styled, useThemeUI } from "theme-ui"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { Grid } from "@theme-ui/components"
import { setLightness } from "polished"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const BlogPage = ({ data }) => {
  const posts = data.allContentfulBlogPost.edges
  const { theme } = useThemeUI()

  return (
    <Layout>
      <SEO title="Blogi" />
      <Flex
        sx={{
          position: "relative",
          height: ["40vh", "50vh"],
          minHeight: [360, null, 480],
          maxHeight: 480,
        }}
      >
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            alignItems: "flex-end",
            py: [4, null, 5],
            lineHeight: "heading",
            bg: "blueLight",
          }}
        >
          <Container>
            <Styled.h1>Blogi</Styled.h1>
          </Container>
        </Flex>
      </Flex>
      <Container sx={{ mt: [-4, null, -5] }}>
        <Grid
          sx={{
            gridTemplateColumns: ["1fr", "1fr 1fr"],
            gridGap: [4, null, 5],
          }}
        >
          {posts &&
            posts.map(p => {
              let post = p.node
              return (
                <Box
                  key={post.id}
                  onClick={() => navigate(`/blogi/${post.slug}`)}
                  sx={{
                    cursor: "pointer",
                    img: {
                      transition: "transform 0.25s !important",
                    },
                    ":hover": {
                      color: "primaryDark",
                      img: {
                        transform: "scale(1.05)",
                      },
                    },
                  }}
                >
                  <Img
                    objectFit="cover"
                    objectPosition="50% 50%"
                    fluid={post.image.fluid}
                    sx={{
                      height: ["50vw", "25vw"],
                      bg: setLightness(0.25, theme.colors.primary),
                      img: {
                        mixBlendMode: "screen",
                      },
                    }}
                  />
                  <Styled.h4
                    as="h2"
                    sx={{ mt: 3, mb: 1, ":not(:first-child)": { mt: 3 } }}
                  >
                    {post.title}
                  </Styled.h4>
                  <Box sx={{ fontSize: [1, 2] }}>
                    {post.publishDate}
                    <span
                      sx={{
                        display: "inline-block",
                        px: 2,
                        color: "primaryDark",
                      }}
                    >
                      ·
                    </span>
                    {post.author.name}
                  </Box>
                </Box>
              )
            })}
        </Grid>
      </Container>
    </Layout>
  )
}
export default BlogPage

export const blogQuery = graphql`
  query {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "fi" }, language: { eq: "Finnish" } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          language
          publishDate(formatString: "D. MMMM[ta] YYYY", locale: "fi-FI")
          body {
            childMdx {
              id
              excerpt
            }
          }
          image {
            description
            fluid {
              sizes
              base64
              aspectRatio
              src
              srcSet
            }
          }

          author {
            name
            jobTitle
          }
        }
      }
    }
  }
`
